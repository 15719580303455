import * as yup from "yup";

import { Order } from "./order";

export const customerTypes = ["household", "business"] as const;
export type CustomerType = (typeof customerTypes)[number];

export type InOutSum = {
  sku: string;
  grand_total: number;
  total_in: number;
  total_out: number;
};

export type Customer = {
  // fields
  id?: number;
  name: string;
  address: string;
  default_amount?: number | null;
  customer_type: CustomerType;
  contact_person?: string;
  contact_number: string;
  lent_containers?: string | null;
  tin: string;
  notes?: string | null;

  // flags
  active: boolean;
  bangkero_commissionable: boolean;
  use_rental_agreements: boolean;
  dr_required: boolean;
  or_required: boolean;

  // dates
  created_at: Date;
  updated_at: Date;
};

export type CustomerShow = Customer & {
  in_out_sums: InOutSum[];
};

export type CustomerIndex = Customer & {
  in_out_sums: InOutSum[];
  last_order: Order | null;
};

export const customerSchema: yup.ObjectSchema<Customer> = yup.object({
  id: yup.number(),
  name: yup.string().default("").required("Name is required"),
  address: yup.string().default("").required("Address is required"),
  default_amount: yup.number().nullable().typeError("Must enter a number"),
  customer_type: yup.string().oneOf(customerTypes).default("household").required("Customer type is required"),
  contact_person: yup.string(),
  contact_number: yup.string().default("").required("Contact number is required"),
  lent_containers: yup.string().nullable(),
  tin: yup.string().default("").defined(),
  notes: yup.string().nullable(),

  active: yup.boolean().default(true).defined(),
  bangkero_commissionable: yup.boolean().default(false).defined(),
  use_rental_agreements: yup.boolean().default(false).defined(),
  dr_required: yup.boolean().default(false).defined(),
  or_required: yup.boolean().default(false).defined(),

  created_at: yup.date().default(new Date()).defined(),
  updated_at: yup.date().default(new Date()).defined(),
});
