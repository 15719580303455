import { titleize } from "../../utils/string";

export const orderStatuses = [
  "new",
  "pickup_pending",
  "pickup_in_progress",
  "washing_pending",
  "washing_in_progress",
  "refilling_pending",
  "refilling_in_progress",
  "delivery_pending",
  "delivery_in_progress",
  "awaiting_payment",
  "payment_received",
  "paid",
  "free",
  "returned",
];

export const orderStatusOptions = orderStatuses.map((os) => ({
  label: titleize(os),
  value: os,
}));

export const getOrderTagColor = (status) => {
  let color = "";
  switch (status) {
    case "new":
      color = "red";
      break;
    case "pickup_pending":
    case "washing_pending":
    case "delivery_pending":
    case "refilling_pending":
      color = "orange";
      break;
    case "pickup_in_progress":
    case "washing_in_progress":
    case "delivery_in_progress":
    case "refilling_in_progress":
      color = "blue";
      break;
    case "awaiting_payment":
    case "returned":
      color = "purple";
      break;
    case "payment_received":
      color = "teal";
      break;
    case "free":
    case "paid":
      color = "green";
      break;
  }

  return color;
};
