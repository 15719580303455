import * as yup from "yup";

export type Attachment = {
  id?: number;
  filename?: string;
  url?: string;
  content_type?: string;
  signed_id: string;
};

export const attachmentSchema: yup.ObjectSchema<Attachment> = yup.object({
  id: yup.number(),
  filename: yup.string(),
  url: yup.string(),
  content_type: yup.string(),
  signed_id: yup.string().defined().default(""),
});
