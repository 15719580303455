import * as yup from "yup";

import { titleize } from "../../utils/string";
import { Customer, customerSchema } from "./customer";

export const dispenserStatuses = ["in_storage", "lent", "pulled_out", "damaged", "rent_to_own", "sold"] as const;
export const dispenserStatusOptions = dispenserStatuses.map((ds) => ({
  label: titleize(ds),
  value: ds,
}));

export type DispenserStatus = (typeof dispenserStatuses)[number];
export type Dispenser = {
  id?: number;
  brand: string;
  model: string;
  serial_number: string;
  cost_price: number;
  selling_price: number;
  status: DispenserStatus;

  acquired_at: Date | null;
  warranty_expires_at: Date | null;
  created_at: Date;
  updated_at: Date;
};

export type DispenserIndex = Dispenser & {
  customer: Customer | null;
};

export const dispenserSchema: yup.ObjectSchema<DispenserIndex> = yup.object({
  id: yup.number(),
  brand: yup.string().default("").required(),
  model: yup.string().default("").required(),
  serial_number: yup.string().default("").required(),
  cost_price: yup.number().default(0).min(1),
  selling_price: yup.number().default(0).min(1),
  status: yup.string().oneOf(dispenserStatuses).default("in_storage"),

  acquired_at: yup.date().nullable().default(null).typeError("date"),
  warranty_expires_at: yup.date().nullable().default(null),

  created_at: yup.date().default(new Date()).defined(),
  updated_at: yup.date().default(new Date()).defined(),

  customer: customerSchema.nullable().default(null),
});
