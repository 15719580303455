import { titleize } from "../../utils/string";

export const productTypes = [
  "refill",
  "gallon",
  "pet_bottle",
  "bottled_water",
  "lpg",
  "rice",
  "caps",
  "seals",
  "dispenser",
  "others",
];

export const productTypeOptions = productTypes.map((pt) => ({
  label: titleize(pt),
  value: pt,
}));
