import * as yup from "yup";

export const paymentMethods = ["cash", "check", "bank_transfer", "e_wallet", "other"] as const;
export const paidWith = ["cash_on_hand", "sales_today", "external"] as const;

export type PaymentMethod = (typeof paymentMethods)[number];
export type PaidWith = (typeof paidWith)[number];

export type Payee = {
  id?: number;
  name: string;
  tin_number: string;
  address: string;
  vat: boolean;
  created_at: Date | string;
  updated_at: Date | string;
};

export type ExpenseCategory = {
  id?: number;
  name: string;
  notes: string;
};

export type Expense = {
  id?: number;
  amount: number;
  paid_at: string | Date;
  paid_with: PaidWith | null;
  remarks: string;

  payee: Payee | null;
  expense_category: ExpenseCategory | null;
};

export const payeeSchema: yup.ObjectSchema<Payee> = yup.object().shape({
  id: yup.number(),
  name: yup.string().default("").required("Name is required"),
  tin_number: yup.string().default(""),
  address: yup.string().default(""),
  vat: yup.boolean().default(false),

  created_at: yup.date().default(new Date()).defined(),
  updated_at: yup.date().default(new Date()).defined(),
});

export const expenseCategorySchema: yup.ObjectSchema<ExpenseCategory> = yup.object({
  id: yup.number(),
  name: yup.string().default("").required("Name is required"),
  notes: yup.string().default(""),
});

export const expenseSchema: yup.ObjectSchema<Expense> = yup.object({
  id: yup.number(),
  amount: yup.number().default(0).min(1, "Amount is required"),
  paid_at: yup.date().default(new Date()),
  paid_with: yup.string().oneOf(paidWith).default("cash_on_hand").required("Paid with is required"),
  remarks: yup.string().default("").required("Remarks is required"),

  expense_category: expenseCategorySchema.nullable().default(null),
  payee: payeeSchema.nullable().default(null),
});
