import React, { createContext, useContext, useEffect, useState } from "react";

import { fetchData } from "../api";
import { Permission, UserWithPermissions } from "../api/types/user";

type AuthContextState = {
  user: string | null;
  permissions: Permission[];
  loaded: boolean;
};

type AuthContextValue = AuthContextState & {
  hasPermission: (p: Permission) => boolean;
  fetchPermissions: VoidFunction;
};

export const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export function AuthProvider({ children }) {
  const [state, setState] = useState<AuthContextState>({
    user: null,
    permissions: [],
    loaded: false,
  });

  const fetchPermissions = () => {
    fetchData<UserWithPermissions>({
      queryKey: ["me"],
    }).then((res) => {
      setState({
        user: res.data.email,
        permissions: res.data.permissions,
        loaded: true,
      });
    });
  };

  useEffect(fetchPermissions, []);

  const hasPermission = (permName: Permission) => state.permissions.includes(permName);

  return <AuthContext.Provider value={{ ...state, fetchPermissions, hasPermission }}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("herp");
  }

  return context;
};
