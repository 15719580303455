import React from "react";

import { Box, Heading, HStack, StackProps, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  subTitle?: string;
  actions?: JSX.Element;
} & Omit<StackProps, "children">;

export default function PageHeader({ title, subTitle, actions, ...props }: Props) {
  return (
    <HStack justify="space-between" mb="6" align="center" {...props}>
      <Box>
        <Heading as="h3" fontSize="2xl" color="gray.600">
          {title}
        </Heading>
        {subTitle && <Text color="gray.500">{subTitle}</Text>}
      </Box>
      {actions && actions}
    </HStack>
  );
}
