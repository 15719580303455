import { titleize } from "../../utils/string";

export const paymentMethods = [
  "cash",
  "check",
  "bank_transfer",
  "e_wallet",
  "other",
];

export const paymentMethodOptions = paymentMethods.map((pm) => ({
  label: titleize(pm),
  value: pm,
}));
