import { QueryKey } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";

import { isObject } from "../utils/object";

const token = document?.querySelector<HTMLMetaElement>("meta[name=csrf-token]")?.content ?? "";
axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
axios.defaults.headers.common["Accept"] = "application/json";
export const apiInstance = axios;

type FetchDataOptions = {
  queryKey: QueryKey;
  format?: "json" | "html" | "csv";
};

export function fetchData<T>({ queryKey: params, format = "json" }: FetchDataOptions) {
  let path = "/api";
  let queryParams: Record<string, unknown> = {};

  params.forEach((param) => {
    const paramIsObject = isObject(param);

    if (typeof param === "string" || typeof param === "number") {
      path += `/${param}`;
    } else if (paramIsObject) {
      let q = {};
      if ("q" in param) {
        q = Object.assign(q, param.q);
      }

      if ("q" in queryParams) {
        q = Object.assign(q, queryParams.q);
      }
      queryParams = { ...queryParams, ...param, q };
    }
  });

  return apiInstance.get<T>(
    `${path}.${format}?${qs.stringify(queryParams, {
      arrayFormat: "brackets",
    })}`,
  );
}

export function create({ resource, values }) {
  return apiInstance.post(`/api/${resource}`, values);
}

export function update({ resource, values }) {
  return apiInstance.patch(`/api/${resource}/${values.id}`, values);
}

export function destroy({ resource, values }) {
  return apiInstance.delete(`/api/${resource}/${values.id}`);
}

export async function logout() {
  await axios.delete("/users/sign_out", {}).finally(() => {
    window.location.reload();
  });
}
