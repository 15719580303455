import * as yup from "yup";

export const allPermissions = [
  "dashboard:read",
  "workboard:read",
  "inventory:create",
  "inventory:read",
  "inventory:update",
  "inventory:delete",
  "monitoring:create",
  "monitoring:read",
  "monitoring:update",
  "monitoring:delete",
  "installments:create",
  "installments:read",
  "installments:update",
  "installments:delete",
  "customers:create",
  "customers:read",
  "customers:update",
  "customers:delete",
  "products:create",
  "products:read",
  "products:update",
  "products:delete",
  "orders:create",
  "orders:read",
  "orders:update",
  "orders:delete",
  "orders:mark_as_paid",
  "payments:create",
  "payments:read",
  "payments:update",
  "payments:delete",
  "payrolls:create",
  "payrolls:read",
  "payrolls:update",
  "payrolls:delete",
  "expenses:create",
  "expenses:read",
  "expenses:update",
  "expenses:delete",
  "payees:create",
  "payees:read",
  "payees:update",
  "payees:delete",
  "expense_categories:create",
  "expense_categories:read",
  "expense_categories:update",
  "expense_categories:delete",
  "employees:create",
  "employees:read",
  "employees:update",
  "employees:delete",
  "attendances:create",
  "attendances:read",
  "attendances:update",
  "attendances:delete",
  "attendances:scan",
  "roles:create",
  "roles:read",
  "roles:update",
  "roles:delete",
] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>,
  );

export const groupedPermissions = groupBy([...allPermissions], (o: Permission) => o.split(":")[0]);

export type Permission = (typeof allPermissions)[number];

export type User = {
  id: number;
  email: string;
  fullname: string;
};

export type UserWithPermissions = User & {
  permissions: Permission[];
};

export const userSchema: yup.ObjectSchema<User> = yup.object({
  id: yup.number().defined(),
  fullname: yup.string().defined(),
  email: yup.string().defined(),
});
