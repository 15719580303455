import * as yup from "yup";

import { Employee } from "./employee";

export type DailyRecord = {
  id?: number;
  employee_id: number;

  time_in: Date;
  time_out: Date;
  total_refill: number;
  base_pay: number;
  incentive_pay: number;
  incentive_count: number;
  //hours_worked: null;

  present_at: Date;
  created_at: Date;
  updated_at: Date;
};

export type Payslip = {
  id?: number;

  employee_id?: Employee["id"];
  first_name: string;
  middle_name: string;
  last_name: string;

  dailies: DailyRecord[];
  daily_rate: number;
  days_rendered: number;
  incentive_count: number;
  incentive_rate: number;
  ca_repayment: number;
  ot_hours: number;
  other_deductions: number;
  sss: number;
  philhealth: number;
  pagibig: number;
  remarks: string;
  start_date: Date;
};

export type Payroll = {
  id?: number;
  start_at: Date;
  end_at: Date;
};

export type PayrollWithPayslips = Payroll & {
  payslips: Payslip[];
};

export const dailyRecordSchema: yup.ObjectSchema<DailyRecord> = yup.object({
  id: yup.number(),
  employee_id: yup.number().defined(),

  time_in: yup.date().default(new Date()).defined(),
  time_out: yup.date().default(new Date()).defined(),
  total_refill: yup.number().default(0).defined(),
  base_pay: yup.number().default(0).defined(),
  incentive_pay: yup.number().default(0).defined(),
  incentive_count: yup.number().default(0).defined(),

  present_at: yup.date().default(new Date()).defined(),
  created_at: yup.date().default(new Date()).defined(),
  updated_at: yup.date().default(new Date()).defined(),
});

export const payslipSchema: yup.ObjectSchema<Payslip> = yup.object({
  id: yup.number(),
  employee_id: yup.number(),
  first_name: yup.string().default("").defined(),
  middle_name: yup.string().default("").defined(),
  last_name: yup.string().default("").defined(),
  daily_rate: yup.number().default(0).defined(),
  days_rendered: yup.number().required("Days renderd is required").default(6),
  incentive_rate: yup.number().required("Incentive rate is required").typeError("Incentive rate must be a number"),
  incentive_count: yup
    .number()
    .required("Incentive count is required")
    .typeError("Incentive count must be a number")
    .default(0),
  ca_repayment: yup.number().default(0).defined(),
  ot_hours: yup.number().required("OT hours is required").default(0).defined(),
  other_deductions: yup.number().default(0).defined(),
  sss: yup.number().default(0).defined(),
  philhealth: yup.number().default(0).defined().typeError("Must be a number"),
  pagibig: yup.number().default(0).defined(),
  remarks: yup.string().default("").defined(),
  start_date: yup.date().default(new Date()).defined(),
  dailies: yup.array(dailyRecordSchema).defined(),
});

export const payrollSchema: yup.ObjectSchema<PayrollWithPayslips> = yup.object({
  id: yup.number(),
  start_at: yup.date().default(new Date()).required("Start date is required"),
  end_at: yup.date().default(new Date()).required("End at is required"),
  payslips: yup.array().of(payslipSchema).default([]),
});
