import { differenceInSeconds, format, formatISO, parseISO, setHours, setMinutes } from "date-fns";

export const toIsoDate = (d: string | Date) =>
  formatISO(typeof d === "string" ? parseISO(d) : d, { representation: "date" });
export const toDate = (d: string | Date) => toIsoDate(typeof d === "string" ? parseISO(d) : d);
export const prettyDateTime = (d: string | Date) => format(typeof d === "string" ? parseISO(d) : d, "PPpp");

export const prettyDate = (d: string | Date) => format(typeof d === "string" ? parseISO(d) : d, "PP");
export const prettyTime = (d: string | Date) => format(typeof d === "string" ? parseISO(d) : d, "p");
export const dayOfWeek = (d: string | Date) => format(typeof d === "string" ? parseISO(d) : d, "EEEE");

export function lateSeconds(timeString, hhmm) {
  const timeIn = parseISO(timeString);
  const [hour, minute] = hhmm.split(":");

  let startTime = timeIn;
  startTime = setHours(startTime, hour);
  startTime = setMinutes(startTime, minute);

  return differenceInSeconds(timeIn, startTime);
}
