import * as yup from "yup";

import { Attachment, attachmentSchema } from "./attachment";
import { ResponseMeta } from "./util";

export type Employee = {
  id?: number;
  uuid?: string;
  active: boolean;

  // personal details
  first_name: string;
  middle_name: string | null;
  last_name: string;
  full_name: string;
  emergency_contact: string;
  emergency_phone: string;

  // employment bullcrap
  daily_rate: number;
  incentive_rate: number;
  pagibig: string | null;
  philhealth: string | null;
  sss: string | null;
  remarks: string;

  work_hours_end: string;
  work_hours_start: string;
  start_date: Date;
  end_date: Date | null;

  image: Attachment | null;
};

export type EmployeesResponse = {
  meta: ResponseMeta;
  employees: Employee[];
};

export const employeeSchema: yup.ObjectSchema<Employee> = yup.object({
  id: yup.number(),
  uuid: yup.string().uuid(),
  active: yup.boolean().default(true),

  first_name: yup.string().default("").required("First name is required"),
  last_name: yup.string().default("").required("Last name is required"),
  middle_name: yup.string().nullable().default(""),
  full_name: yup.string().default(""),
  emergency_contact: yup.string().default(""),
  emergency_phone: yup.string().default(""),

  daily_rate: yup
    .number()
    .default(0)
    .positive()
    .required("Daily rate is required")
    .typeError("Daily rate must be a number"),
  incentive_rate: yup
    .number()
    .default(0)
    .positive()
    .required("Incentive rate is required")
    .typeError("Incentive rate must be a number"),
  pagibig: yup.string().nullable().default(""),
  philhealth: yup.string().nullable().default(""),
  sss: yup.string().nullable().default(""),
  remarks: yup.string().defined().default(""),

  start_date: yup.date().default(new Date()).required("Start date is required"),
  work_hours_start: yup.string().default("").required("Work start is required"),
  work_hours_end: yup.string().default("").required("Work end is required"),
  end_date: yup.date().defined().nullable().default(null),

  image: attachmentSchema.nullable(),
});
