import * as yup from "yup";

import { Customer, customerSchema } from "./customer";
import { OrderItem, orderItemSchema } from "./orderItem";
import { Payment, paymentSchema, PaymentShow } from "./payments";
import { Product, productSchema } from "./product";
import { ResponseMeta } from "./util";

export const orderStates = [
  "new",
  "pickup_pending",
  "pickup_in_progress",
  "washing_pending",
  "washing_in_progress",
  "refilling_pending",
  "refilling_in_progress",
  "delivery_pending",
  "delivery_in_progress",
  "awaiting_payment",
  "payment_received",
  "paid",
  "free",
  "returned",
] as const;

export type OrderState = (typeof orderStates)[number];

export type Order = {
  id?: number;
  state: OrderState;
  remarks: string;
  invoice_number?: string | null;
  dr_number?: string | null;
  subtotal: string;
  grand_total: string;

  // flags
  receipt_issued: boolean;
  bangkero_commissionable: boolean;

  // dates
  ordered_at: Date | string;
  created_at: Date | string;
  updated_at: Date;
};

export type OrderSums = {
  bground: number;
  bgslim: number;
  ownround: number;
  ownslim: number;
};

export type OrderIndex = Order & {
  customer: Customer;
  quick_summary: string;
  pickup_summary: string;
  has_pickup: boolean;
  refill: OrderSums;
};

export type OrderForm = Omit<Order, "receipt_issued" | "subtotal" | "grand_total"> & {
  customer?: Customer | null;
  pickup: Partial<OrderSums>;
  refill: Partial<OrderSums>;
  order_items: OrderItem[];
  payments: Payment[];

  newProduct: Product | null;
};

export type OrdersResponse = {
  meta: ResponseMeta;
  orders: OrderIndex[];
};

export const countSchema = yup
  .number()
  .min(0, "Pano ka oorder ng negative na tubig?")
  .typeError("must be a number")
  .default(0);

export const orderSumsSchema: yup.ObjectSchema<OrderSums> = yup.object({
  bground: countSchema,
  bgslim: countSchema,
  ownround: countSchema,
  ownslim: countSchema,
});

const drRequirableStatuses = ["delivery_pending", "delivery_in_progress", "awaiting_payment", "paid"];

export const orderSchema: yup.ObjectSchema<Order> = yup.object({
  id: yup.number(),
  state: yup.string().oneOf(orderStates).default("new"),
  remarks: yup.string().default("").defined(),
  invoice_number: yup.string().default(""),
  dr_number: yup.string().default(""),
  bangkero_commissionable: yup.boolean().default(false),
  receipt_issued: yup.boolean().default(false),
  subtotal: yup.string().default("0"),
  grand_total: yup.string().default("0"),

  ordered_at: yup.date().default(new Date()),
  created_at: yup.date().default(new Date()),
  updated_at: yup.date().default(new Date()),
});

export const paymentShowSchema: yup.ObjectSchema<PaymentShow> = paymentSchema.shape({
  customer: customerSchema.default(null).nullable().typeError("Customer is required"),
  order: orderSchema.default(null).nullable(),
});

export const orderFormSchema: yup.ObjectSchema<OrderForm> = orderSchema.shape({
  invoice_number: yup.string().when(["customer", "state"], {
    is(customer: Customer, state: OrderState) {
      return customer?.or_required && drRequirableStatuses.includes(state);
    },
    then: (schema) => schema.required("OR number is required for this customer").nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  dr_number: yup
    .string()
    .default("")
    .when(["customer", "state"], {
      is(customer: Customer, state: OrderState) {
        return customer?.dr_required && drRequirableStatuses.includes(state);
      },
      then: (schema) => schema.required("DR number is required for this customer"),
    }),

  customer: customerSchema.default(null).nullable().typeError("Customer is required"),
  pickup: orderSumsSchema.defined(),
  refill: orderSumsSchema.defined(),
  order_items: yup.array(orderItemSchema).default([]),
  payments: yup.array(paymentSchema).default([]),

  newProduct: productSchema.nullable().default(null),
});
