import * as yup from "yup";

import { Employee, employeeSchema } from "./employee";

export type Attendance = {
  id?: number;
  employee: Employee;
  present_at: Date;
  time_in: Date | null;
  time_out: Date | null;
};

export const attendanceSchema: yup.ObjectSchema<Attendance> = yup.object({
  id: yup.number(),
  present_at: yup.date().default(new Date()),
  time_in: yup.date().nullable().default(null),
  time_out: yup.date().nullable().default(null),
  employee: employeeSchema.required("Employee is required").typeError("Employee is required"),
});
