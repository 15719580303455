import * as yup from "yup";

import { ResponseMeta } from "./util";

export const productType = [
  "dispenser",
  "gallon",
  "caps",
  "seals",
  "refill",
  "pet_bottle",
  "bottled_watter",
  "others",
  "lpg",
  "rice",
] as const;
export type ProductType = (typeof productType)[number];

export type Product = {
  id?: number;
  name: string;
  product_type: ProductType;
  sku: string;
  cost_price: number;
  srp: number;
};

export const productSchema: yup.ObjectSchema<Product> = yup.object({
  id: yup.number(),
  name: yup.string().default("").required("Name is required"),
  sku: yup.string().default("").required("SKU is required"),
  cost_price: yup.number().default(0).required("Cost price is required").min(0, "Please provide a positive number"),
  srp: yup.number().default(0).typeError("SRP is no a number").min(0, "Please provide a positive number"),
  product_type: yup.string().oneOf(productType).default("refill"),
});

export type ProductsResponse = {
  meta: ResponseMeta;
  orders: Product[];
};
