import * as yup from "yup";

import { Product, productSchema } from "./product";

export const orderItemType = ["pickup", "delivery", "refill", "sale"] as const;
export type OrderItemType = (typeof orderItemType)[number];

export type OrderItem = {
  id?: number;
  item_type: OrderItemType;
  name: string;
  price: number;
  product: Product;
  quantity: number;
  sku: string;
};

export const orderItemSchema: yup.ObjectSchema<OrderItem> = yup.object({
  id: yup.number(),
  name: yup.string().required("Name is required"),
  sku: yup.string().required("SKU is required"),
  item_type: yup.string().oneOf(orderItemType).default("refill"),
  quantity: yup.number().default(0).min(0, "Pano ka oorder ng negative na tubig?").typeError("must be a number"),
  price: yup.number().required("Price is required"),
  product: productSchema,
});
