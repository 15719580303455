import * as yup from "yup";

import { Customer } from "./customer";
import { Order } from "./order";

export const paymentMethods = ["cash", "check", "bank_transfer", "e_wallet", "other"] as const;

export type PaymentMethod = (typeof paymentMethods)[number];

export type Payment = {
  id?: number;
  amount?: number | null;
  invoice_number?: string | null;
  payment_method: PaymentMethod;
  remarks: string;

  paid_at: Date;
  created_at: Date;
  updated_at: Date;
};

export type PaymentShow = Payment & {
  order: Order | null;
  customer: Customer | null;
};

export const paymentSchema: yup.ObjectSchema<Payment> = yup.object({
  id: yup.number(),
  amount: yup.number().nullable().typeError("Amount must be a number"),
  payment_method: yup.string().oneOf(paymentMethods).default("cash").required("Payment method is required"),
  remarks: yup.string().default(""),
  invoice_number: yup.string().nullable().default(null),

  paid_at: yup.date().default(new Date()).defined(),
  created_at: yup.date().default(new Date()).defined(),
  updated_at: yup.date().default(new Date()).defined(),
});
