export function capitalize(s: string): string {
  if (!s) {
    return "";
  }
  return s.substring(0, 1).toUpperCase() + s.substring(1);
}

export function titleize(s: string): string {
  if (!s) {
    return "";
  }

  return s
    .replace(/[\s_-]/g, " ")
    .split(" ")
    .map(capitalize)
    .join(" ");
}

export function upcase(s: string): string {
  if (!s) {
    return "";
  }
  return s.toUpperCase();
}

export function sentenceize(s: string): string {
  if (!s) {
    return "";
  }

  return capitalize(s.replace(/_/g, " "));
}

export function newLineToBr(s: string): string {
  return s.replace(/\n/g, "<br/>");
}
