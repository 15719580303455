import * as yup from "yup";

import { allPermissions, Permission, User, userSchema } from "./user";

export type Role = {
  id?: number;
  description: string;
  name: string;
  permissions: Permission[];
  users: User[];
};

export type RoleForm = Omit<Role, "permissions"> & {
  permissions: Record<Permission, boolean>;
};

const permissionsSchema = allPermissions.reduce(
  (out, p) => {
    out[p] = yup.boolean().defined().default(false);
    return out;
  },
  {} as Record<Permission, yup.BooleanSchema<boolean, yup.AnyObject, false, "d">>,
);

export const roleSchema = yup.object({
  id: yup.number(),
  name: yup.string().required("Name is required").default(""),
  description: yup.string().required("Description is required").default(""),
  permissions: yup.object().shape(permissionsSchema),
  users: yup.array(userSchema).default([]),
});
