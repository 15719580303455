import { titleize } from "../../utils/string";

export const paymentMethods = [
  "cash",
  "check",
  "bank_transfer",
  "e_wallet",
  "other",
];

export const paidWith = ["cash_on_hand", "sales_today", "external"];

export const paymentMethodOptions = paymentMethods.map((pm) => ({
  label: titleize(pm),
  value: pm,
}));

export const paidWithOptions = paidWith.map((pm) => ({
  label: titleize(pm),
  value: pm,
}));
