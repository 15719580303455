/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { Suspense, useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi";

import { Button, Icon, Text, VStack } from "@chakra-ui/react";

import { create } from "../../api";
import Card from "../../components/Card";
import ContentWrapper from "../../components/ContentWrapper";
import PageHeader from "../../components/PageHeader";
import useQueryParams from "../../hooks/useQueryParams";
import { prettyDate, prettyDateTime } from "../../utils/date";
import { titleize } from "../../utils/string";

const QrReader = React.lazy(() => import("react-qr-scanner"));

export default function Scan() {
  const mode = String(useQueryParams()?.mode);
  const [recorded, setRecorded] = useState(null);
  const [scanned, setScanned] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (scanned) {
      setLoading(true);
      create({
        resource: `attendances/${mode}`,
        values: {
          uuid: scanned,
        },
      })
        .then((res) => {
          setRecorded(res.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [scanned]);

  function renderRecorder(recorded) {
    const { employee, time_in, time_out, present_at } = recorded;
    return (
      <Card p="6" w="container.sm" mb={6}>
        <VStack spacing="2">
          <Icon as={HiCheck} color="white" fontSize="6xl" bg="green.500" borderRadius="full" />
          <Text fontWeight="semibold" fontSize="lg">
            {employee.first_name} {employee.last_name}
          </Text>
          <Text color="gray.500">Date: {prettyDate(present_at)}</Text>
          <Text color="gray.500">Time in: {time_in && prettyDateTime(time_in)}</Text>
          <Text color="gray.500">Time out: {time_out && prettyDateTime(time_out)}</Text>
        </VStack>
      </Card>
    );
  }

  return (
    <ContentWrapper>
      <PageHeader title={`Attendance: ${titleize(mode)}`} />
      <Suspense fallback={<div></div>}>
        {recorded && renderRecorder(recorded)}
        {loading && <div>loading</div>}
        {error && <Text>Employee not found</Text>}
        {!scanned ? (
          <QrReader
            delay={500}
            style={{ height: "auto", width: "auto" }}
            onScan={(a) => {
              if (a && a?.text) {
                setScanned(a.text);
              }
            }}
            onError={(e) => console.log("error", e)}
          />
        ) : (
          <Button
            colorScheme="blue"
            onClick={() => {
              setScanned(null);
              setRecorded(null);
              setError(false);
            }}
          >
            Another?
          </Button>
        )}
      </Suspense>
    </ContentWrapper>
  );
}
